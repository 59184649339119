import { Regimes } from '@shared/data/constants'

export enum INTEGRATION_PLATFORM {
  BRAZE = 'braze',
  SFMC = 'sfmc', // salesforce marketing cloud
  KLAVIYO = 'klaviyo',
  // ADOBE_CAMPAIGN = 'adobe_compaign',
}

export const STANDARD_TIMEZONE = 'standard-timezone'
export const RECIPIENT_TIMEZONE = 'recipient-timezone'

export const PLATFORM_DISPLAY_NAME = {
  [INTEGRATION_PLATFORM.BRAZE]: 'Braze',
  [INTEGRATION_PLATFORM.SFMC]: 'Salesforce Marketing Cloud',
  [INTEGRATION_PLATFORM.KLAVIYO]: 'Klaviyo',
  '': 'platform',
  // [INTEGRATION_PLATFORM.ADOBE_CAMPAIGN]: 'Adobe Campaign',
}

export const CAMPAIGN_PLATFORM_TERMINOLOGY = {
  [INTEGRATION_PLATFORM.BRAZE]: 'Campaign',
  [INTEGRATION_PLATFORM.SFMC]: 'Journey',
  [INTEGRATION_PLATFORM.KLAVIYO]: 'Flow',
  '': 'Platform',
}

export const SERVICE_ERROR_GLOBAL_MESSAGE = 'One of the services doesn\'t respond as expected. Please refresh the page and try again.'

export const GENERATED_VARIANTS_LIMIT = 1000
export const LIQUID_TAGS_REGEX_TOKENIZERS = {
  [INTEGRATION_PLATFORM.BRAZE]: /\{\{\$\{(.*?)\}\}\}/g,
  [INTEGRATION_PLATFORM.SFMC]: /%%([^%]+)%%/g,
  [INTEGRATION_PLATFORM.KLAVIYO]: /\{\{(.*?)\}\}/g,
}

export const OFE_REGIMES_ARRAY = [Regimes.OFFERFIT, Regimes.CONTROL]

export enum MODULE_STATUS {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
  MISSING_STEPS = 'missing-steps',
  NON_EDITABLE = 'non-editable',
}
