<script setup lang="ts">
import { computed, ref } from 'vue'
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import AppSettings from '@app/AppSettings.ts'
import type { FormExposedProperties } from '@/level4/utils/level4Forms.ts'
import { type EmailCampaign, useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'

const { experimenterFormState, loadCampaigns, updateExperimenterFormState } = useExperimenterFormState()
const { clientName, experimenterName, pipelineName } = AppSettings
const queryClient = useQueryClient()

const currentBAUSelectedCampaignIds = ref<string[]>([])
const search = ref<string>('')

syncRefs(() => toRaw(experimenterFormState.value?.selectedBAUCampaignIds), currentBAUSelectedCampaignIds, { immediate: true })

const loadCampaignsQuery = useQuery({
  queryKey: ['get-campaigns-bau', clientName.value, experimenterName.value, pipelineName.value],
  queryFn: async () => loadCampaigns(),
  enabled: !!experimenterFormState.value?.name,
})

whenever(loadCampaignsQuery.isError, () => {
  handlePortalError(loadCampaignsQuery.error?.value?.message, { defaultUserErrorText: `Couldn't load campaigns. Please check that the integration data is correct` })
})

const campaignReferenceName = computed(() => {
  return experimenterFormState.value?.platform === 'braze' ? 'Campaigns or Canvases' : experimenterFormState.value?.platform === 'sfmc' ? 'Journeys' : 'Campaigns'
})

const title = computed(() =>
    `Which of these ${campaignReferenceName.value} would you like to use as the Business as Usual for this experimenter?`)

const filteredImportedCampaigns = computed(() => {
  // Remove already selected campaign from list
  const filteredCampaigns = loadCampaignsQuery.data?.value?.filter(campaign => campaign.id !== experimenterFormState.value?.brazeCampaignId)
  if (search.value) {
    return filteredCampaigns?.filter((campaign: EmailCampaign) => {
      return campaign.name.toLowerCase().includes(search.value.toLowerCase())
    })
  }
  else {
    return filteredCampaigns
  }
})

async function refreshDataClickHandler(): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: ['get-campaigns-bau'] })
}

function selectUpdateHandler(value: string[]) {
  currentBAUSelectedCampaignIds.value = value
}

const hasSelectedCampaigns = computed(() => !!currentBAUSelectedCampaignIds.value.length)
const dataIsComplete = computed(() => experimenterFormState.value?.isAddingBauCampaign ? hasSelectedCampaigns.value && !loadCampaignsQuery.isPending.value : true)

defineExpose<FormExposedProperties>({
  dataIsComplete,
  submitHandler: async () => {
    await updateExperimenterFormState({ selectedBAUCampaignIds: currentBAUSelectedCampaignIds.value })
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full" headerSize="medium" :title="title"
  >
    <div class="flex justify-center">
      <div class="max-w-[42rem] w-full flex flex-col gap-6">
        <div class="flex justify-center">
          <BasicLink
            href="https://app.tango.us/app/workflow/-WIP--Reporting-on-BAU-in-the-OfferFit-Self-Serve-portal-18311ba85feb47f3adaf138f38d1b8d8"
            target="_blank"
            class="cursor-pointer text-link"
            withExternalIcon
          >
            More information about reporting on BAU campaigns
          </BasicLink>
        </div>
        <AudienceMultipleSelectCheckBox
          :arrayValue="currentBAUSelectedCampaignIds"
          :listData="filteredImportedCampaigns"
          :isLoading="loadCampaignsQuery.isPending.value"
          :queryIsSuccess="loadCampaignsQuery.isSuccess.value"
          :noDataMessage="`No ${campaignReferenceName} found`"
          :dataErrorMessage="`Error fetching ${campaignReferenceName}. Please try again later or contact an OfferFit representative.`"
          :searchPlaceholder="`Search for ${campaignReferenceName} by group name...`"
          @refresh="refreshDataClickHandler"
          @modelValueChange="selectUpdateHandler"
        />
      </div>
    </div>
  </WizardFormPageLayout>
</template>
