import AppSettings from '@app/AppSettings.ts'
import type { RouteLocationRaw } from 'vue-router'
import { useExperimenterFormState } from '../composables/useExperimenterFormState'
import { Level4Route } from '../router/level4'
import { Level4Module } from '../data/forms'
import { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import {
  getExistingIntegrationCredentials,
  hasIntegrationEstablished,
} from '@/level4/services/PlatformIntegrationService.ts'
import { useFeatureFlags } from '@/level4/composables/useFeatureFlags'
import TypeWizard from '@/level4/views/modules/type/TypeWizard.vue'
import IntegrationsWizard from '@/level4/views/modules/integrations/IntegrationsWizard.vue'
import CredentialsWizard from '@/level4/views/modules/use-credentials/CredentialsWizard.vue'
import BrazeWizard from '@/level4/views/modules/braze/BrazeWizard.vue'
import KlaviyoWizard from '@/level4/views/modules/klaviyo/KlaviyoWizard.vue'
import SfmcWizard from '@/level4/views/modules/sfmc/SfmcWizard.vue'
import AudienceWizard from '@/level4/views/modules/audience/AudienceWizard.vue'
import TemplatesWizard from '@/level4/views/modules/templates/TemplatesWizard.vue'
import TemplatesSelectionWizard from '@/level4/views/modules/templates-selection/TemplatesSelectionWizard.vue'
import ChooseElementsWizard from '@/level4/views/modules/choose-elements/ChooseElementsWizard.vue'
import CreateVariantsWizard from '@/level4/views/modules/create-variants/CreateVariantsWizard.vue'
import BauChooseWizard from '@/level4/views/modules/bau-choose/BauChooseWizard.vue'
import FrequencyWizard from '@/level4/views/modules/frequency/FrequencyWizard.vue'
import TimeWizard from '@/level4/views/modules/time/TimeWizard.vue'
import GuardrailsWizard from '@/level4/views/modules/guardrails/GuardrailsWizard.vue'
import LaunchSfmcWizard from '@/level4/views/modules/launch-sfmc/LaunchSfmcWizard.vue'
import LaunchKlaviyoWizard from '@/level4/views/modules/launch-klaviyo/LaunchKlaviyoWizard.vue'
import BauConfigureWizard from '@/level4/views/modules/bau-configure/BauConfigureWizard.vue'

export const L4_MODULES = [
  {
    path: 'experimenters/:experimenterName/type',
    name: Level4Module.TYPE,
    component: TypeWizard,
  },
  {
    path: 'experimenters/:experimenterName/integrations',
    name: Level4Module.INTEGRATIONS,
    meta: { title: 'Integrations' },
    component: IntegrationsWizard,
  },
  {
    path: 'experimenters/:experimenterName/use-credentials',
    name: Level4Module.USE_CREDENTIALS,
    meta: { title: 'Use Credentials' },
    component: CredentialsWizard,
  },
  {
    path: `experimenters/:experimenterName/integrations/${INTEGRATION_PLATFORM.BRAZE}`,
    name: Level4Module.BRAZE,
    meta: { title: 'Braze' },
    component: BrazeWizard,
  },
  {
    path: `experimenters/:experimenterName/integrations/${INTEGRATION_PLATFORM.SFMC}`,
    name: Level4Module.SFMC,
    meta: { title: 'SFMC' },
    component: SfmcWizard,
  },
  {
    path: `experimenters/:experimenterName/integrations/${INTEGRATION_PLATFORM.KLAVIYO}`,
    name: Level4Module.KLAVIYO,
    meta: { title: 'Klaviyo' },
    component: KlaviyoWizard,
  },
  {
    path: 'experimenters/:experimenterName/audience',
    name: Level4Module.AUDIENCE,
    meta: { title: 'Audience' },
    component: AudienceWizard,
  },
  {
    path: 'experimenters/:experimenterName/templates',
    name: Level4Module.TEMPLATES,
    meta: { title: 'Templates' },
    component: TemplatesWizard,
  },
  {
    path: 'experimenters/:experimenterName/templates-selection',
    name: Level4Module.TEMPLATES_SELECTION,
    meta: { title: 'Templates Selection' },
    component: TemplatesSelectionWizard,
  },
  {
    path: 'experimenters/:experimenterName/templates/:templateId/choose-elements',
    name: Level4Module.CHOOSE_ELEMENTS,
    meta: { title: 'Choose Elements' },
    component: ChooseElementsWizard,
  },
  {
    path: 'experimenters/:experimenterName/templates/:templateId/create-variants',
    name: Level4Module.CREATE_VARIANTS,
    meta: { title: 'Create Variants' },
    component: CreateVariantsWizard,
  },
  {
    path: 'experimenters/:experimenterName/bau-audience',
    name: Level4Module.BAU_CHOOSE,
    meta: { title: 'BAU Audience' },
    component: BauChooseWizard,
  },
  {
    path: 'experimenters/:experimenterName/bau-audience',
    name: Level4Module.BAU_CONFIGURE,
    meta: { title: 'BAU Audience' },
    component: BauConfigureWizard,
  },
  {
    path: 'experimenters/:experimenterName/frequency',
    name: Level4Module.FREQUENCY,
    meta: { title: 'Frequency' },
    component: FrequencyWizard,
  },
  {
    path: 'experimenters/:experimenterName/time',
    name: Level4Module.TIME,
    meta: { title: 'Time' },
    component: TimeWizard,
  },
  {
    path: 'experimenters/:experimenterName/guardrails',
    name: Level4Module.GUARDRAILS,
    meta: { title: 'Guardrails' },
    component: GuardrailsWizard,
  },
  {
    path: 'experimenters/:experimenterName/launch-sfmc',
    name: Level4Module.LAUNCH_SFMC,
    meta: { title: 'Launch SFMC' },
    component: LaunchSfmcWizard,
  },
  {
    path: 'experimenters/:experimenterName/launch-klaviyo',
    name: Level4Module.LAUNCH_KLAVIYO,
    meta: { title: 'Launch Klaviyo' },
    component: LaunchKlaviyoWizard,
  },
]

export function useL4Modules() {
  const route = useRoute()
  const router = useRouter()
  const {
    experimenterFormState,
    isPlatformIntegrationFormStateDataEmpty,
    isTypeCompleted,
    isAudienceCompleted,
    isEmailCompleted,
    isFrequencyAndDaysCompleted,
    isTimeCompleted,
    isGuardrailsCompleted,
  } = useExperimenterFormState()
  const { isEmailVariantsGuardrailsEnabled, isCalendarSendsEnabled } = useFeatureFlags()
  const currentModule = route.name as Level4Module
  const currentModuleIndex = getModuleIndex(currentModule)

  function getModuleIndex(moduleName: Level4Module) {
    return L4_MODULES.findIndex(module => module.name === moduleName)
  }
  function getPreviousModule() {
    const previousRouteName = router.resolve({ path: router.options.history.state.back as string }).name
    const previousModuleIndex = getModuleIndex(previousRouteName as Level4Module)
    if (previousModuleIndex < currentModuleIndex) {
      return previousRouteName
    }
    return undefined
  }

  async function redirectToNextModule(): Promise<RouteLocationRaw> {
    let targetPlatformModule: RouteLocationRaw
    if (experimenterFormState.value?.platform) {
      if (experimenterFormState.value.platform === INTEGRATION_PLATFORM.BRAZE) {
        targetPlatformModule = { name: Level4Module.BRAZE }
      }
      else if (experimenterFormState.value.platform === INTEGRATION_PLATFORM.SFMC) {
        targetPlatformModule = { name: Level4Module.SFMC }
      }
      else {
        targetPlatformModule = { name: Level4Module.KLAVIYO }
      }
    }

    if (currentModule === Level4Module.BAU_CHOOSE && !!experimenterFormState.value?.isAddingBauCampaign) {
      return { name: Level4Module.BAU_CONFIGURE }
    }

    if (currentModule === Level4Module.INTEGRATIONS && targetPlatformModule) {
      const hasIntegration = await hasIntegrationEstablished(AppSettings.experimenterName.value, AppSettings.clientName.value)
      if (hasIntegration || !isPlatformIntegrationFormStateDataEmpty.value) {
        return targetPlatformModule
      }

      const data = await getExistingIntegrationCredentials(experimenterFormState.value?.platform)
      if (data?.length > 0) {
        return { name: Level4Module.USE_CREDENTIALS }
      }
      else {
        return targetPlatformModule
      }
    }

    if (currentModule === Level4Module.USE_CREDENTIALS) {
      return targetPlatformModule
    }

    if (currentModule === Level4Module.TEMPLATES_SELECTION) {
      return { name: Level4Module.TEMPLATES }
    }

    if (currentModule === Level4Module.CHOOSE_ELEMENTS) {
      return { name: Level4Module.CREATE_VARIANTS }
    }

    if (currentModule === Level4Module.CREATE_VARIANTS) {
      return { name: Level4Module.TEMPLATES }
    }

    const completedModules = new Map<Level4Module, boolean>([
      [Level4Module.TYPE, isTypeCompleted.value],
      [Level4Module.INTEGRATIONS, !isPlatformIntegrationFormStateDataEmpty.value],
      [Level4Module.AUDIENCE, isAudienceCompleted.value],
      [Level4Module.TEMPLATES, isEmailCompleted.value],
      [Level4Module.BAU_CHOOSE, true],
      [Level4Module.BAU_CONFIGURE, true],
      [Level4Module.FREQUENCY, !!isFrequencyAndDaysCompleted.value],
      [Level4Module.TIME, isTimeCompleted.value],
    ])

    if (!isCalendarSendsEnabled.value) {
      completedModules.delete(Level4Module.TYPE)
    }

    if (isEmailVariantsGuardrailsEnabled.value) {
      completedModules.set(Level4Module.GUARDRAILS, !!isGuardrailsCompleted.value)
    }

    if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.KLAVIYO) {
      completedModules.set(Level4Module.LAUNCH_KLAVIYO, !!experimenterFormState.value?.platformNeedsUpdateFlag)
    }
    else if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC) {
      completedModules.set(Level4Module.LAUNCH_SFMC, !!experimenterFormState.value?.platformNeedsUpdateFlag)
    }

    const modules = [...completedModules.keys()]
    const nextModule = modules.find((module: Level4Module, index) => modules.indexOf(currentModule) < index && !completedModules.get(module))

    if (!nextModule) {
      // const parentPath = route.path.split('/').slice(0, -1).join('/')
      return { name: Level4Route.EXPERIMENTER }
    }

    return { name: nextModule }
  }

  async function redirectToPreviousModule(): Promise<RouteLocationRaw> {
    const previousModule = getPreviousModule()
    if (previousModule) {
      return { name: previousModule }
    }
    return { name: Level4Route.EXPERIMENTER }
  }

  return {
    redirectToNextModule,
    redirectToPreviousModule,
    currentModule,
    currentModuleIndex,
    getPreviousModule,
  }
}
