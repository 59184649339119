<script setup lang="tsx">
import type { DataTableColumns } from "naive-ui"
import { NButton, NTag } from "naive-ui"
import TableEditableTextarea from '@shared/components/basic/TableEditableTextarea.vue'
interface SubjectLineListRow {
  id: string
  label: string
  tags: string[]
}

interface Props {
  data: SubjectLineListRow[]
  loading: boolean
  tokenizer: RegExp
}

withDefaults(defineProps<Props>(), {  })

const emit = defineEmits<{
  delete: [id: string]
  updateRow: [id: string, label: string]
}>()

const editingRowId = ref<string>()
function createColumns(): DataTableColumns<SubjectLineListRow> {
  return [
    {
      title: "Subject line",
      key: "label",
      render(row) {
        return <TableEditableTextarea
          modelValue={row.label}
          showInput={editingRowId.value === row.id}
          ghost
          tokenizerRegex={ __props.tokenizer }
          onBlur={(value) => {
            emit('updateRow', row.id, value)
            editingRowId.value = undefined
          }}
        />
      },
    },
    {
      title: "Tags",
      key: "tags",
      render(row) {
        const variantTags = () =>
          row.tags.map((element) => (
            <NTag round bordered={false} size="small">
              {element}
            </NTag>
          ))

        return (
          <div flex="~ wrap" gap="1">
            {variantTags()}
          </div>
        )
      },
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render(row, index) {
        const EditButton = () => (
          <NButton
            type="primary"
            quaternary
            size="small"
            circle
            onClick={() => editingRowId.value = row.id}
          >
            {{ icon: () => <div class="i-solar-pen-2-outline"></div> }}
          </NButton>
        )

        const DeleteButton = () => (
          <NButton type="primary" quaternary size="small" circle onClick={() => emit('delete', row.id)}>
            {{
              icon: () => <div class="i-solar-trash-bin-trash-outline"></div>,
            }}
          </NButton>
        )

        return (
          <div flex="~ gap-2 justify-end">
            <EditButton />
            <DeleteButton />
          </div>
        )
      },
    },
  ]
}
</script>

<template>
  <div class="subject-lines-list-wrapper">
    <NDataTable
      :data="data"
      :columns="createColumns()"
      :bordered="false"
      :loading="loading"
    />
  </div>
</template>

<style scoped>
:deep(.n-data-table-th) {
  @apply hidden;
}
</style>
