<script setup lang="ts">
import hat from 'hat'
import type { INTEGRATION_PLATFORM } from '@/level4/data/l4_constants'
import { LIQUID_TAGS_REGEX_TOKENIZERS } from '@/level4/data/l4_constants'
import { getTooSimilarVariants } from '@/level4/utils/LLM/LLMHelpers'
import type { GeneratedVariantsOption } from '@/level4/stores/useExperimenterVariantsFormsStore'
import { useExperimenterVariantsFormsStore } from '@/level4/stores/useExperimenterVariantsFormsStore'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { LLMService } from '@/level4/services/LLMService'
import { getCountableNounForm } from '@shared/utils/helpers'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const [showGenerateMoreSubjectLines, toggleShowGenerateMoreSubjectLines] = useToggle()

const { experimenterFormState } = useExperimenterFormState()
const experimenterVariantsFormStore = useExperimenterVariantsFormsStore()
const { generatedSubjectLinesVariants, tooSimilarSubjectLinesVariants, feedbackForGeneratedSubjectLines, isLoaded } = storeToRefs(experimenterVariantsFormStore)

const currentGeneratedSubjectLinesVariants = ref<GeneratedVariantsOption[]>([])
const currentTooSimilarSubjectLinesVariants = ref<GeneratedVariantsOption[]>([])
const isLoadingEmailSubjectLinesVariants = ref(false)

syncRefs(generatedSubjectLinesVariants, currentGeneratedSubjectLinesVariants, { immediate: true })
syncRefs(tooSimilarSubjectLinesVariants, currentTooSimilarSubjectLinesVariants, { immediate: true })

const platform = computed<INTEGRATION_PLATFORM | undefined>(() => experimenterFormState.value?.platform)

async function loadSubjectLinesVariants() {
  isLoadingEmailSubjectLinesVariants.value = true
  const newVariants = (await experimenterVariantsFormStore.loadSubjectLineVariants()) || []
  currentGeneratedSubjectLinesVariants.value = [...(currentGeneratedSubjectLinesVariants.value || []), ...newVariants]
  currentTooSimilarSubjectLinesVariants.value = getTooSimilarVariants(currentGeneratedSubjectLinesVariants.value, currentTooSimilarSubjectLinesVariants.value)
  toggleShowGenerateMoreSubjectLines()
  isLoadingEmailSubjectLinesVariants.value = false
}

onMounted(async () => {
  await until(isLoaded).toBe(true)
  if (generatedSubjectLinesVariants.value?.length === 0) {
    // before we call loadCtaVariants, we need to check if we have use case config loaded
    await until(platform).toBeTruthy()

    await loadSubjectLinesVariants()
  }
})

function addTooSimilarVariant(index: number) {
  const toAdd = currentTooSimilarSubjectLinesVariants.value.splice(index, 1)
  currentGeneratedSubjectLinesVariants.value.push(...toAdd)
}

function deleteSubjectLineVariantById(id: string) {
  currentGeneratedSubjectLinesVariants.value.splice(
    currentGeneratedSubjectLinesVariants.value.findIndex(variant => variant.id === id),
    1,
  )
}

function addCustomSubjectLineVariant() {
  currentGeneratedSubjectLinesVariants.value.push({
    id: hat(),
    label: '',
    tags: [],
  })
}

async function editSubjectLineLabelById(id: string, label: string) {
  const tags = await LLMService.getTagsForLabel(label)
  currentGeneratedSubjectLinesVariants.value = currentGeneratedSubjectLinesVariants.value.map((variant) => {
    if (variant.id === id) {
      variant.label = label
      variant.tags = tags ?? []
    }
    return variant
  })

  currentTooSimilarSubjectLinesVariants.value = getTooSimilarVariants(currentGeneratedSubjectLinesVariants.value, currentTooSimilarSubjectLinesVariants.value)
}

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => !!currentGeneratedSubjectLinesVariants.value?.length && !currentTooSimilarSubjectLinesVariants.value.length),
  submitHandler: async () => {
    await experimenterVariantsFormStore.updateGeneratedSubjectLinesVariants(currentGeneratedSubjectLinesVariants.value)
  },
})
</script>

<template>
  <WizardFormPageLayout container-size="medium" header-size="medium" :title="`Here are ${currentGeneratedSubjectLinesVariants?.length ?? 'some'} subject lines you could use with this email.`" subtitle="You can edit my suggestions, delete them, or ask me for new suggestions.">
    <div class="subject-lines-list" w="full">
      <SubjectLinesList :data="currentGeneratedSubjectLinesVariants" :loading="isLoadingEmailSubjectLinesVariants" :tokenizer="LIQUID_TAGS_REGEX_TOKENIZERS[platform]" @delete="id => deleteSubjectLineVariantById(id)" @update-row="(id, label) => editSubjectLineLabelById(id, label)"></SubjectLinesList>
      <div class="footer-actions" flex="~" gap="4" p="y-2">
        <NButton quaternary type="primary" strong @click="addCustomSubjectLineVariant()">Add your own
          <template #icon>
            <div class="i-solar-add-square-outline"></div>
          </template>
        </NButton>
        <NButton quaternary type="primary" strong @click="toggleShowGenerateMoreSubjectLines()">{{ currentGeneratedSubjectLinesVariants?.length ? 'Generate more' : 'Generate subject lines' }}
          <template #icon>
            <div class="i-solar-magic-stick-3-outline"></div>
          </template>
        </NButton>
      </div>
    </div>
    <NCard v-if="currentTooSimilarSubjectLinesVariants.length > 0" max="w-lg">
      <div class="show-more-wrapper" flex="~ col" gap="4" text="center"><span>{{currentTooSimilarSubjectLinesVariants?.length}} {{ getCountableNounForm(currentTooSimilarSubjectLinesVariants?.length, 'variant', 'variants') }} seem too similar to existing variants, review them below
          <SubjectLinesListTooSimilar v-if="currentTooSimilarSubjectLinesVariants?.length > 0" :data="currentTooSimilarSubjectLinesVariants" :loading="false" @add="(index) => addTooSimilarVariant(index)" @delete="(index)=> currentTooSimilarSubjectLinesVariants.splice(index,1)"></SubjectLinesListTooSimilar></span></div>
    </NCard>
    <NCard v-if="showGenerateMoreSubjectLines" max="w-lg" m="x-auto">
      <div class="show-more-wrapper" flex="~ col" gap="4" text="center"><span>You can tell me more about the kinds of subject lines you’d like me to generate, or just click “Generate more.”</span>
        <SimpleTextarea v-model="feedbackForGeneratedSubjectLines" placeholder="Just show me some more!"></SimpleTextarea>
      </div>
      <template #footer>
        <div class="show-me-more-subject-lines-footer" flex="~" gap="4" justify="center">
          <NButton quaternary type="primary" @click="toggleShowGenerateMoreSubjectLines()">Cancel</NButton>
          <NButton secondary type="primary" :disabled="isLoadingEmailSubjectLinesVariants" @click="loadSubjectLinesVariants">Generate more</NButton>
        </div>
      </template>
    </NCard>
  </WizardFormPageLayout>
</template>

<style scoped>
</style>
