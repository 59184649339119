<script setup lang="ts">
import hat from 'hat'
import { getCountableNounForm } from '@shared/utils/helpers'
import { getTooSimilarVariants } from '@/level4/utils/LLM/LLMHelpers'
import type { GeneratedVariantsOption } from '@/level4/stores/useExperimenterVariantsFormsStore'
import { useExperimenterVariantsFormsStore } from '@/level4/stores/useExperimenterVariantsFormsStore'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { LLMService } from '@/level4/services/LLMService'
import { LIQUID_TAGS_REGEX_TOKENIZERS } from '@/level4/data/l4_constants'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const [showGenerateMoreCTALines, toggleGenerateMoreCTALines] = useToggle()
const { experimenterFormState } = useExperimenterFormState()
const experimenterVariantsFormStore = useExperimenterVariantsFormsStore()

const { generatedCtaVariants, tooSimilarCtaVariants, feedbackForGeneratedCta, isLoaded } = storeToRefs(experimenterVariantsFormStore)

const currentGeneratedCtaVariants = ref<GeneratedVariantsOption[]>([])
const currentTooSimilarCtaVariants = ref<GeneratedVariantsOption[]>([])
const currentFeedbackForGeneratedCta = ref<string | undefined>('')
const loadingEmailCtaVariants = ref(false)

syncRefs(feedbackForGeneratedCta, currentFeedbackForGeneratedCta, { immediate: true })
syncRefs(generatedCtaVariants, currentGeneratedCtaVariants, { immediate: true })
syncRefs(tooSimilarCtaVariants, currentTooSimilarCtaVariants, { immediate: true })

onMounted(async () => {
  await until(isLoaded).toBe(true)

  if (generatedCtaVariants.value.length === 0) {
    // before we call loadCtaVariants, we need to check if we have use case config loaded
    await until(() => experimenterFormState.value?.platform).toBeTruthy()
    await loadCTAVariants()
  }
})

function addTooSimilarVariant(index: number) {
  const toAdd = currentTooSimilarCtaVariants.value.splice(index, 1)
  currentGeneratedCtaVariants.value.push(...toAdd)
}

async function loadCTAVariants() {
  loadingEmailCtaVariants.value = true
  const newVariants = (await experimenterVariantsFormStore.loadCtaVariants()) || []
  currentGeneratedCtaVariants.value = [...(currentGeneratedCtaVariants.value || []), ...newVariants]
  currentTooSimilarCtaVariants.value = getTooSimilarVariants(currentGeneratedCtaVariants.value, currentTooSimilarCtaVariants.value)
  loadingEmailCtaVariants.value = false
}

async function loadMoreCtaVariantsClickHandler() {
  experimenterVariantsFormStore.updateCTAUserFeedback(currentFeedbackForGeneratedCta.value)
  return loadCTAVariants()
}

function deleteCtaVariantById(id: string) {
  currentGeneratedCtaVariants.value.splice(
    currentGeneratedCtaVariants.value.findIndex(variant => variant.id === id),
    1,
  )
}

function addCustomCtaVariant(label?: string) {
  currentGeneratedCtaVariants.value.push({
    id: hat(),
    label: label ?? '',
    tags: [],
  })
}

async function editCtaLabelById(id: string, label: string) {
  const tags = await LLMService.getTagsForLabel(label)
  currentGeneratedCtaVariants.value = currentGeneratedCtaVariants.value.map((variant) => {
    if (variant.id === id) {
      variant.label = label
      variant.tags = tags ?? []
    }

    return variant
  })

  currentTooSimilarCtaVariants.value = getTooSimilarVariants(currentGeneratedCtaVariants.value, currentTooSimilarCtaVariants.value)
}

defineExpose<FormExposedProperties>({
  dataIsComplete: computed(() => !!currentGeneratedCtaVariants.value.length && !currentTooSimilarCtaVariants.value.length),
  submitHandler: async () => {
    await experimenterVariantsFormStore.updateCTATextSelection({
      generatedCtaVariantsOptions: currentGeneratedCtaVariants.value,
      tooSimilarCtaVariants: currentTooSimilarCtaVariants.value,
      feedbackForGeneratedCta: currentFeedbackForGeneratedCta.value,
    })
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="medium" headerSize="medium" :title="`Here are ${currentGeneratedCtaVariants?.length ?? 'some'} CTAs you could use.`" subtitle="You can edit my suggestions, delete them, or ask me for new suggestions.">
    <div class="cta-lines-list" w="full">
      <CTAVariantsList :data="currentGeneratedCtaVariants" :loading="loadingEmailCtaVariants" :tokenizer="LIQUID_TAGS_REGEX_TOKENIZERS[platform]" @delete="deleteCtaVariantById($event)" @update-row="(id, label) => editCtaLabelById(id, label)" />
      <div class="footer-actions" flex="~" gap="4" p="y-2">
        <NButton quaternary type="primary" strong @click="addCustomCtaVariant()">
          Add your own
          <template #icon>
            <div class="i-solar-add-square-outline" />
          </template>
        </NButton>
        <NButton quaternary type="primary" strong @click="toggleGenerateMoreCTALines()">
          Generate more
          <template #icon>
            <div class="i-solar-magic-stick-3-outline" />
          </template>
        </NButton>
      </div>
      <NCard v-if="currentTooSimilarCtaVariants?.length > 0" max="w-lg">
        <div class="show-more-wrapper" flex="~ col" gap="4" text="center">
          <span>{{ currentTooSimilarCtaVariants.length }} {{ getCountableNounForm(currentTooSimilarCtaVariants.length, 'variant', 'variants') }} seem too similar to existing variants, review them below
            <SubjectLinesListTooSimilar v-if="currentTooSimilarCtaVariants.length > 0" :data="currentTooSimilarCtaVariants" :loading="false" @add="(index) => addTooSimilarVariant(index)" @delete="(index) => currentTooSimilarCtaVariants.splice(index, 1)" /></span>
        </div>
      </NCard>
    </div>
    <NCard v-if="showGenerateMoreCTALines" max="w-lg" m="x-auto">
      <div class="show-more-wrapper" flex="~ col" gap="4" text="center">
        <span>You can tell me more about the kinds of CTA lines you’d like me to generate, or just click “Generate more.”</span>
        <SimpleTextarea v-model="currentFeedbackForGeneratedCta" placeholder="Just show me some more!" />
      </div>
      <template #footer>
        <div class="show-me-more-cta-lines-footer" flex="~" gap="4" justify="center">
          <NButton quaternary type="primary" @click="showGenerateMoreCTALines = false">
            Cancel
          </NButton>
          <NButton secondary type="primary" :disabled="loadingEmailCtaVariants" @click="loadMoreCtaVariantsClickHandler">
            Generate more
          </NButton>
        </div>
      </template>
    </NCard>
  </WizardFormPageLayout>
</template>
