<script setup lang="ts">
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import AppSettings from '@app/AppSettings.ts'
import { SHORT_ERROR_MSG } from '@/level4/services/OFEService.ts'
import type { AudienceModel } from '@/level4/models/braze/AudienceModel.ts'
import type { FormExposedProperties } from '@/level4/utils/level4Forms.ts'
import { PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants.ts'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'

const {
  experimenterFormState,
  validateAudience,
  loadAudiences,
  updateBAUReportingConfiguration,
} = useExperimenterFormState()
const { clientName, experimenterName, pipelineName } = AppSettings

const currentSelectedBAUAudienceId = ref<string>()
const search = ref<string>('')

syncRefs(() => toRaw(experimenterFormState.value?.selectedBAUAudienceId), currentSelectedBAUAudienceId, { immediate: true })

const loadAudiencesQuery = useQuery({
  queryKey: ['get-audiences-bau', clientName.value, experimenterName.value, pipelineName.value],
  queryFn: async () => getAudiences(),
  enabled: !!experimenterFormState.value?.name,
})

async function getAudiences(useCache: boolean = true): Promise<AudienceModel[] | undefined> {
  try {
    const res = await loadAudiences(useCache)
    return res.data.attributes.importedAudiences
  }
  catch (e: any) {
    handlePortalError(e, { defaultUserErrorText: `Couldn't load audiences. ${experimenterFormState.value?.importedAudiences.length ? 'You can see audiences taken from cache. ' : ''}Please check that the integration data is correct` })
    return experimenterFormState.value?.importedAudiences
  }
}

const isLoadingAudienceDetail = ref<boolean>(false)
const audienceSizeMap = ref<Map<string, number>>(new Map())
const audienceErrorMap = ref<Map<string, Pick<AudienceModel, 'error' | 'errorCode'>>>(new Map())

async function validateSelectedAudiences() {
  const audiences = loadAudiencesQuery.data?.value?.filter((audience) => {
    return currentSelectedBAUAudienceId.value === audience.id
  })

  if (!audiences?.length) {
    return
  }

  isLoadingAudienceDetail.value = true
  for (const audience of audiences) {
    if (audienceSizeMap.value.get(audience.id) === undefined) {
      const validationData = await validateAudience(audience)
      if (validationData?.error_msg) {
        audienceErrorMap.value.set(audience.id, { error: validationData.error_msg, errorCode: validationData.error_code })
      }
      if (validationData && validationData.result.size !== null) {
        audienceSizeMap.value.set(audience.id, validationData.result.size)
      }
    }
  }
  isLoadingAudienceDetail.value = false
}

watchThrottled([currentSelectedBAUAudienceId], validateSelectedAudiences, { throttle: 650, immediate: true })

async function getAudiencesWithoutCache(): Promise<void> {
  await getAudiences(false)
}

const invalidAudiences = computed(() => {
  return loadAudiencesQuery.data?.value?.filter((audience: string) => {
    return currentSelectedBAUAudienceId.value === audience.id
  }).filter((audience) => {
    return audience.error
  })
})

const title = computed(() => `Select your BAU audience from ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform]}`)

const filteredImportedAudiences = computed(() => {
  const filteredAudiences = loadAudiencesQuery.data?.value?.filter(audience => !experimenterFormState.value?.selectedAudienceIds?.includes(audience.id))
  return search.value
    ? filteredAudiences?.filter((audience) => {
      return audience.name.toLowerCase().includes(search.value.toLowerCase())
    })
    : filteredAudiences
})

async function refreshDataClickHandler(): Promise<void> {
  audienceSizeMap.value.clear()
  audienceErrorMap.value.clear()
  await getAudiencesWithoutCache()
  await validateSelectedAudiences()
}

function getAudienceHelpText(audience: AudienceModel): string {
  const errorCode = audienceErrorMap.value.get(audience.id)?.errorCode
  const shortErrorMsg = errorCode && SHORT_ERROR_MSG[errorCode]
  if (errorCode && shortErrorMsg) {
    return shortErrorMsg
  }

  if (audienceSizeMap.value.get(audience.id) !== undefined) {
    return `${audienceSizeMap.value.get(audience.id) ?? 0} recipients`
  }

  if (audienceSizeMap.value.get(audience.id) === undefined && currentSelectedBAUAudienceId.value === audience.id) {
    return 'Loading...'
  }

  return ''
}

const isDataLoading = computed(() => loadAudiencesQuery.isPending.value && isLoadingAudienceDetail.value)
const hasInvalidAudiences = computed(() => !!invalidAudiences.value?.length)
const dataIsComplete = computed(() => !isDataLoading.value && !!currentSelectedBAUAudienceId.value && !hasInvalidAudiences.value)

defineExpose<FormExposedProperties>({
  dataIsComplete,
  submitHandler: async () => {
    await updateBAUReportingConfiguration(
      experimenterFormState.value?.isAddingBauCampaign || true,
      experimenterFormState.value?.selectedBAUCampaignIds,
      currentSelectedBAUAudienceId.value,
    )
  },
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="full" headerSize="medium" :title="title"
    subtitle="Which of these groups represents the audience receiving Business as Usual communications?"
  >
    <div class="flex justify-center">
      <div v-if="!loadAudiencesQuery.isPending.value" class="max-w-[42rem] w-full flex flex-col gap-6">
        <div class="flex justify-center">
          <BasicLink
            href="https://app.tango.us/app/workflow/-WIP--Reporting-on-BAU-in-the-OfferFit-Self-Serve-portal-18311ba85feb47f3adaf138f38d1b8d8"
            target="_blank"
            class="cursor-pointer text-link"
            withExternalIcon
          >
            More information about reporting on BAU campaigns
          </BasicLink>
        </div>
        <BasicInput v-model="search" type="text" placeholder="Search for more audiences by group name..." isSearchBar />
        <div v-if="filteredImportedAudiences?.length" class="flex flex-col gap-4">
          <NScrollbar
            class="max-h-sm rounded bg-gray-50 px-8 shadow-inner"
            trigger="none"
          >
            <NRadioGroup
              v-model:value="currentSelectedBAUAudienceId"
              class="flex flex-col gap-2 py-8 w-full"
            >
              <RadioInput
                v-for="audience in filteredImportedAudiences" :key="`${audience.id}_${audienceErrorMap.get(audience.id)?.error}_${audienceSizeMap.get(audience.id)}`"
                :isSelected="audience.id === currentSelectedBAUAudienceId"
                :value="audience.id"
                :label="audience.name"
                :helperText="getAudienceHelpText(audience)"
                :errorText="audienceErrorMap.get(audience.id)?.error"
                class="bg-white mb-4"
              />
            </NRadioGroup>
          </NScrollbar>
          <div
            class="flex cursor-pointer justify-center text-sm font-medium text-offerfit-bright-purple"
            @click="refreshDataClickHandler"
          >
            <div class="i-solar-refresh-bold mr-2 h-5 w-5" />
            Refresh list
          </div>
        </div>

        <div
          v-if="(filteredImportedAudiences?.length ?? 0) <= 0"
          class="h-[18rem] flex flex-col items-center justify-center gap-2 border-1 border-gray-300 rounded-md"
        >
          <p v-if="!filteredImportedAudiences?.length" class="text-center text-gray-500">
            No audiences found.
          </p>
          <p v-else class="text-center text-sm text-gray-500">
            Make sure you have created an audience in your platform and that it has at least one user. <br>
            If at least one audience has been created in your platform and it does not appear here,
            please return to Step 1 - Platform Connection - and verify that all settings are correct.
          </p>
        </div>
      </div>
      <NSpin v-else class="mt-4 h-250px w-full" />
    </div>
  </WizardFormPageLayout>
</template>

<style scoped>
input[type="radio"]::before {
  content: "";
  color: green;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  transform: scale(.5);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em green;
}
</style>
