import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import { vueQueryClient } from '@app/utils/vueQueryClient.ts'
import * as Sentry from '@sentry/vue'
import { handlePortalError } from '@shared/utils/errorHandling.ts'
import { debug } from '@shared/utils/logger'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'
import { UseCaseStatus as ExperimenterStatus } from '@shared/data/constants'
import type { SingleVariantRow } from '../components/email-templates/EmailTemplatesConfirmVariantsTable.vue'
import { Level4Module, TemplatesSelectionSteps, TemplatesSteps } from '../data/forms'
import { INTEGRATION_PLATFORM } from '../data/l4_constants'
import type { AudienceModel } from '../models/braze/AudienceModel'
import type {
  ExperimenterFormState,
  ExperimenterType,
  ExperimenterVariantsFormStateType,
} from '../models/server/FormStateModel'
import FormsStateService from '../services/FormsStateService'
import OFEService from '../services/OFEService'
import type {
  BrazeIntegrationData,
  KlaviyoIntegrationData,
  KlaviyoIntegrationValidationResponse,
  SFMCIntegrationData,
  SFMCIntegrationValidationResponse,
} from '../services/PlatformIntegrationService'
import PlatformIntegrationService from '../services/PlatformIntegrationService'
import UsecaseService from '../services/UsecaseService'
import type { FormRouterTarget } from '../utils/level4Forms'
import type { VariantsCartesianProduct } from '../utils/variants/variantsCartesianProduct'
import { useFeatureFlags } from './useFeatureFlags'
import type { EmailGuardrail } from '@/level4/utils/guardrails/utils.ts'
import type { ConfigDimensionActionBank } from '@/usecases/models/server/ActionModel'
import type { Frequency } from '@/usecases/models/FrequencyModel.ts'
import { DimensionKey } from '@/shared/data/constants'
import { useLvl4Usecase } from '@/level4/composables/useLvl4Usecase.ts'
import type { ClientModel } from '@/clients/models/server/ClientModel'
import UseCaseService from '@/usecases/services/UseCaseService.ts'

export enum LoadingStatus {
  LOADED = 'loaded',
  LOADING = 'loading',
  FAILED = 'failed',
}

export interface BaseEmailTemplate {
  id: string
  name: string
  subject_line: string
  content_hash: string
  html_content: string
  description: string
  metadata: Record<string, any>
  deleted?: boolean
}

export interface CreatedTemplatesMap { [importedTemplateId: string]: BaseEmailTemplate }

export interface ImportedEmailTemplate extends BaseEmailTemplate {
  thumbnail_url: string
  campaign_id?: string
}

export interface EmailCampaign {
  id: string
  name: string
  description: string
  email_templates?: ImportedEmailTemplate[]
  platform_params: unknown
}

function removeIdFromObject<T extends { id?: string, importedTemplateId?: string, createdTemplateId?: string }>(dataObject: T) {
  const { id, importedTemplateId, createdTemplateId, ...rest } = dataObject
  return rest
}

export function useExperimenterFormState(experimenterName?: string) {
  const _experimenterName = computed(() => experimenterName || AppSettings.experimenterName.value)
  if (!_experimenterName.value) { handlePortalError('experimenter name not defined') }

  const { clientName, pipelineName } = AppSettings
  const { isEmailVariantsGuardrailsEnabled, isCalendarSendsEnabled } = useFeatureFlags()
  const { usecase, reloadUseCaseData } = useLvl4Usecase(clientName.value, pipelineName.value, _experimenterName.value)

  const { data: experimenterFormState, isLoading: isFormStateLoading } = useQuery({
    queryKey: ['formState', _experimenterName],
    queryFn: async () => FormsStateService.loadExperimenter(clientName.value, pipelineName.value, _experimenterName.value),
    staleTime: 30000,
    refetchOnMount: true,
    enabled: computed(() => !!_experimenterName.value),
  })

  const { mutateAsync: updateExperimenterFormState } = useMutation({
    mutationFn: async (data: Partial<ExperimenterFormState>) => {
      if (!experimenterFormState.value?.id) { throw new Error('updateExperimenterFormState: experimenterFormState is not defined') }
      return FormsStateService.saveFormsState(experimenterFormState.value.id, data)
    },
    onSuccess: (response) => {
      vueQueryClient.setQueryData(['formState', _experimenterName], { id: response.data.id, ...response.data.attributes })
    },
  })

  const isLive = computed(() => {
    if (!_experimenterName.value) { return false }
    return experimenterFormState.value?.status === ExperimenterStatus.LIVE
  })

  async function deleteFormState() {
    if (!experimenterFormState.value?.id) { throw new Error('deleteFormState: experimenterFormState is not defined') }

    await FormsStateService.deleteFormState(experimenterFormState.value.id)
    await vueQueryClient.invalidateQueries({ queryKey: ['formState', _experimenterName] })

    if (experimenterFormState.value.usecase?.id) {
      await UseCaseService.deleteUseCase(experimenterFormState.value.usecase?.id)
    }
  }

  // integrations
  async function setMarketingPlatform(platform: INTEGRATION_PLATFORM) {
    const updatedFormState = await FormsStateService.setMarketingPlatform(clientName.value, pipelineName.value, _experimenterName.value, platform)
    vueQueryClient.setQueryData(['formState', _experimenterName], { id: updatedFormState.data.id, ...updatedFormState.data })
  }

  async function createIntegrationDataInVault() {
    if (!_experimenterName.value) { throw new Error('createIntegrationDataInVault: experimenter is not defined') }
    if (!store.getters['client/client']?.name) { throw new Error('createIntegrationDataInVault: client is not defined') }
    const updatedFormState = await PlatformIntegrationService.createIntegrationDataInVault(_experimenterName.value, clientName.value)
    vueQueryClient.setQueryData(['formState', _experimenterName], { id: updatedFormState.data.id, ...updatedFormState.data })
  }

  async function updateOFEConfigs() {
    if (!_experimenterName.value) { throw new Error('createIntegrationDataInVault: experimenter is not defined') }

    const response = await FormsStateService.updateConfigFromPyMS(clientName.value, pipelineName.value, _experimenterName.value, import.meta.env.VITE_IS_LOCAL === 'true')

    store.commit('pipeline/updatePipelineConfig', response.data.pipelineConfig)
    store.commit('pipeline/updatePipelineMetadata', response.data.pipelineMetadata)
    await reloadUseCaseData()
  }

  async function validateIntegration() {
    if (!_experimenterName.value) { throw new Error('validateIntegration: experimenter is not defined') }
    if (!experimenterFormState.value) { throw new Error('validateIntegration: experimenterFormState is not defined') }

    try {
      return await PlatformIntegrationService.validateIntegration(_experimenterName.value, clientName.value, pipelineName.value, Object.values(experimenterFormState.value.offerfitCreatedTemplates))
    }
    catch (e) {
      // we don't want to bubble up an error, since it might be OK to not pass the validation
      console.warn(e)
    }
  }

  async function setMarketingCampaignId(campaignId: string) {
    const updatedFormState = await FormsStateService.setMarketingCampaign(clientName.value, pipelineName.value, _experimenterName.value, campaignId)
    vueQueryClient.setQueryData(['formState', _experimenterName], { id: updatedFormState.data.id, ...updatedFormState.data })
  }

  async function setBrazeEmailFrom(emailFrom: string) {
    return updateExperimenterFormState({ brazeEmailFrom: emailFrom })
  }

  async function setMarketingMessageVariationId(messageVariationId: string) {
    return updateExperimenterFormState({ brazeMessageVariationId: messageVariationId })
  }
  async function setBrazeDashboardUrl(dashboardUrl: string) {
    return updateExperimenterFormState({ brazeDashboardUrl: dashboardUrl })
  }

  async function setBrazeAppId(appId: string) {
    return updateExperimenterFormState({ brazeAppId: appId })
  }

  async function setBrazeApiKey(brazeApiKey: string) {
    return updateExperimenterFormState({ brazeApiKey })
  }

  async function setBrazeIntegrationCredentials(data: BrazeIntegrationData) {
    return updateExperimenterFormState(data)
  }

  async function setSFMCIntegrationCredentials(data: SFMCIntegrationData) {
    return updateExperimenterFormState(data)
  }

  async function setKlaviyoIntegrationCredentials(data: KlaviyoIntegrationData) {
    return updateExperimenterFormState(data)
  }

  // audiences
  async function updateSelectedAudienceId(audienceIds: string[]) {
    if (!_experimenterName.value) { throw new Error('updateSelectedAudienceId: experimenter is not defined') }
    try {
      if (experimenterFormState.value?.selectedBAUAudienceId) {
        audienceIds.push(experimenterFormState.value?.selectedBAUAudienceId)
      }
      const response = await FormsStateService.updateSelectedAudienceIds(clientName.value, pipelineName.value, _experimenterName.value, audienceIds)
      vueQueryClient.setQueryData(['formState', _experimenterName], response.data)
    }
    catch (e) {
      Sentry.captureException(e)
      console.warn(e)
      throw new Error('Error while trying to update selected audiences')
    }
  }

  async function updateBAUReportingConfiguration(isAddingBAU: boolean, selectedCampaignIds?: string[], audienceId?: string) {
    if (!_experimenterName.value) { throw new Error('updateBauAudience: experimenter is not defined') }
    if (isAddingBAU.value && (!selectedCampaignIds.value?.length || !audienceId.value?.length)) {
      throw new Error('updateBauAudience: If adding BAU group, audience and campaign ids need to be selected')
    }
    try {
      await FormsStateService.updateBAUReportingConfiguration(clientName.value, pipelineName.value, _experimenterName.value, isAddingBAU, selectedCampaignIds, audienceId, import.meta.env.VITE_IS_LOCAL === 'true')
    }
    catch (e) {
      Sentry.captureException(e)
      console.warn(e)
      throw new Error('Error while trying to update BAU configuration')
    }
  }

  async function loadAudiences(useCache: boolean = true) {
    if (!_experimenterName.value) { throw new Error('loadAudiences: experimenter is not defined') }
    const audiencesResponse = await OFEService.getAudiences(_experimenterName.value, clientName.value, useCache)
    return updateExperimenterFormState({ importedAudiences: audiencesResponse.result.audiences })
  }

  async function validateAudience(audienceData?: AudienceModel) {
    if (!experimenterFormState.value) { throw new Error('validateAudience: formstate is not defined') }
    if (!_experimenterName.value) { throw new Error('validateAudience: experimenter is not defined') }
    if (!audienceData) { throw new Error('validateAudience: audienceData is not defined') }

    try {
      return await OFEService.validateAudience(audienceData, _experimenterName.value, clientName.value)
    }
    catch (error: any) {
      if (error?.data?.error_code && ['SFMC-AUDIENCES-1008', 'SFMC-AUDIENCES-1009', 'KLAVIYO-AUDIENCES-1002', 'KLAVIYO-AUDIENCES-1003', 'BRAZE-AUDIENCES-1003'].includes(error.data.error_code)) {
        // do nothing
      }
      else {
        handlePortalError(error, { defaultUserErrorText: 'Couldn\'t get information about the audience. Please try again later.' })
      }
    }
  }

  async function updateSelectedAudienceRegimePercent(selectedOfferfitGroupPercent: number, selectedControlGroupPercent: number) {
    if (!_experimenterName.value) { throw new Error('updateSelectedAudienceRegimePercent: experimenter is not defined') }
    try {
      const response = await FormsStateService.updateSelectedAudienceRegimePercent(clientName.value, pipelineName.value, _experimenterName.value, selectedOfferfitGroupPercent, selectedControlGroupPercent)
      vueQueryClient.setQueryData(['formState', _experimenterName], response.data)
    }
    catch (e) {
      Sentry.captureException(e)
      console.warn(e)
      throw new Error('Error while trying to update selected audiences regime percents')
    }
  }

  async function updateSelectedTypeAndDuration({ type, startDate, endDate }: { type: ExperimenterType, startDate: string | null, endDate: string | null }) {
    if (!_experimenterName.value) { throw new Error('updateSelectedTypeAndDuration: experimenter is not defined') }
    try {
      const response = await FormsStateService.updateSelectedTypeAndDuration(clientName.value, pipelineName.value, _experimenterName.value, type, startDate, endDate)
      vueQueryClient.setQueryData(['formState', _experimenterName], response.data)
    }
    catch (e) {
      Sentry.captureException(e)
      console.warn(e)
      throw new Error('Error while trying to update selected type and duration')
    }
  }

  const allConfirmedVariantListLength = computed(() => {
    if (!experimenterFormState.value) { return }
    return Object.values(experimenterFormState.value.variantsDataMap).map(item => item.generatedVariants).flat().length
  })

  const generatedVariantsCombinatoryList = computed(() => {
    if (!experimenterFormState.value?.variantsDataMap) { return }
    return Object.values(experimenterFormState.value.variantsDataMap).map(item => item.generatedVariants)
  })

  const presentCategoriesOnGeneratedVariants = computed(() => {
    if (!experimenterFormState.value) { return }
    if (!generatedVariantsCombinatoryList.value) { return }

    const categories = {
      subjectLine: false,
      cta: false,
      image: false,
    }
    const variantList = Object.values(generatedVariantsCombinatoryList.value).flat()
    while (Object.values(categories).includes(false)) {
      const variant = variantList.shift()
      if (!variant) { break }
      if (variant.subjectLine) { categories.subjectLine = true }
      if (variant.cta) { categories.cta = true }
      if (variant.image) { categories.image = true }
    }
    return categories
  })

  // the size of selected audiences should be retrieved from Events Hub, to reflect latest data state
  const selectedAudienceSize = computed(() => {
    if (!experimenterFormState.value) { return }
    if (!experimenterFormState.value?.selectedAudienceIds) { return }
    if (!experimenterFormState.value?.importedAudiences) { return }

    return experimenterFormState.value?.selectedAudienceIds?.reduce((sum, id) => {
      const audience = experimenterFormState.value?.importedAudiences?.find(audience => audience.id === id)
      return sum + (audience?.size ?? 0)
    }, 0)
  })

  // Campaigns
  async function setImportedCampaigns(campaigns: EmailCampaign[]) {
    await updateExperimenterFormState({ importedCampaigns: campaigns })
  }

  async function addCurrentImportedTemplatesFromImportedCampaign(selectedTemplates: ImportedEmailTemplate[]) {
    const templatesToKeep = (experimenterFormState.value?.currentImportedTemplates ?? []).filter(
      template => selectedTemplates.some(
        templateToAdd => template.id !== templateToAdd.id,
      ),
    )

    await updateExperimenterFormState({ currentImportedTemplates: [...templatesToKeep, ...selectedTemplates] })

    const variantsDataMap = toRaw(experimenterFormState.value?.variantsDataMap) || {}

    ;(experimenterFormState.value?.currentImportedTemplates ?? []).forEach((template) => {
      if (!variantsDataMap[template.id]) {
        variantsDataMap[template.id] = {
          varySubjectLine: false,
          generatedCtaVariants: [],
          selectedImagesForVariants: [],
        } as Partial<ExperimenterVariantsFormStateType>
      }
    })
    await updateExperimenterFormState({ variantsDataMap })
  }

  async function loadCampaigns() {
    const client = store.getters['client/client'] as ClientModel
    if (!_experimenterName.value) { throw new Error('loadCampaigns: experimenter is not defined') }
    const campaignsResponse = await OFEService.getCampaigns(_experimenterName.value, client.name)
    // type is identical to what we retrieve
    return campaignsResponse.result.campaigns as EmailCampaign[]
  }

  const campaignTemplatesLoadingMap = ref(new Map<string, LoadingStatus>())

  async function loadCampaignTemplates(campaign?: EmailCampaign) {
    if (!_experimenterName.value) { throw new Error('loadCampaignDetail: experimenter is not defined') }
    if (!campaign) { throw new Error('loadCampaignTemplates: campaign not found') }

    campaignTemplatesLoadingMap.value.set(campaign.id, LoadingStatus.LOADING)
    const response = await OFEService.getCampaignDetails(campaign.id, _experimenterName.value, clientName.value, campaign.platform_params)
    if (!response?.result || response.error_msg || response.error_code) {
      campaignTemplatesLoadingMap.value.set(campaign.id, LoadingStatus.FAILED)
      return []
    }
    else {
      campaignTemplatesLoadingMap.value.set(campaign.id, LoadingStatus.LOADED)
      return response.result.email_templates
    }
  }

  function checkWhetherImportedTemplatesAreAlreadyInUse(selectedTemplates: ImportedEmailTemplate[]) {
    const templatesToCheck = (experimenterFormState.value?.currentImportedTemplates ?? []).filter(
      template => selectedTemplates.some(
        templateToAdd => template.id === templateToAdd.id,
      ),
    )

    if (templatesToCheck && templatesToCheck.length > 0) {
      return templatesToCheck
    }

    return []
  }

  // emails and variants
  // TODO: move the function body to the BE side
  async function createOrUpdatePlatformTemplates() {
    if (!experimenterFormState.value) { throw new Error('createOrUpdatePlatformTemplates: experimenter is not defined') }

    // we should not create new template for those imported templates that has OfferFit template created in the platform
    const importedTemplatesThatHasCreatedOfferFitTemplate = Object.keys(experimenterFormState.value.offerfitCreatedTemplates)

    const createTemplatesData = experimenterFormState.value.currentImportedTemplates
      .filter(importedTemplate => !importedTemplatesThatHasCreatedOfferFitTemplate.includes(importedTemplate.id))
      .map((emailTemplate: ImportedEmailTemplate) => {
        return {
          id: emailTemplate.id,
          name: emailTemplate.name,
          html: emailTemplate.html_content,
          cta: experimenterFormState.value.variantsDataMap[emailTemplate.id]?.selectedCTAHtmlElementIdentifiers?.cta,
          heroImg: experimenterFormState.value.variantsDataMap[emailTemplate.id]?.selectedCTAHtmlElementIdentifiers?.image,
          metadata: emailTemplate.metadata,
        }
      })

    const mapCreatedTemplateFunction = (emailTemplate: ImportedEmailTemplate) => {
      const offerfitCreatedTemplateDetails = experimenterFormState.value.offerfitCreatedTemplates[emailTemplate.id]
      return {
        importedTemplateId: emailTemplate.id,
        name: offerfitCreatedTemplateDetails?.name,
        html: offerfitCreatedTemplateDetails?.html_content,
        cta: experimenterFormState.value.variantsDataMap[emailTemplate.id]?.selectedCTAHtmlElementIdentifiers?.cta,
        heroImg: experimenterFormState.value.variantsDataMap[emailTemplate.id]?.selectedCTAHtmlElementIdentifiers?.image,
        metadata: emailTemplate.metadata,
      }
    }

    const updateTemplatesData = experimenterFormState.value.currentImportedTemplates
      .filter(importedTemplate => importedTemplatesThatHasCreatedOfferFitTemplate.includes(importedTemplate.id))
    // need to take new id from offerfitCreatedTemplates
      .map((item) => {
        return {
          ...mapCreatedTemplateFunction(item),
          createdTemplateId: experimenterFormState.value.offerfitCreatedTemplates[item.id]?.id,
        }
      })

    if (updateTemplatesData.length) {
      await updateVariantsChangedFlag()
    }

    if (createTemplatesData?.length) {
      try {
        const response = await OFEService.createOrUpdatePlatformTemplates(_experimenterName.value, clientName.value, { templates: createTemplatesData.map(removeIdFromObject) })
        const offerfitCreatedTemplates = toRaw(experimenterFormState.value?.offerfitCreatedTemplates)
        response.result?.templates?.forEach((templateData: BaseEmailTemplate, idx: number) => {
          offerfitCreatedTemplates[createTemplatesData[idx].id] = templateData
        })
        await updateExperimenterFormState({ offerfitCreatedTemplates })
      }
      catch (error: any) {
        handlePortalError(error, { defaultUserErrorText: 'Couldn\'t create templates in the marketing platform. Please contact support.', detailedErrorText: error.message })
        throw new Error('Error while trying to create templates')
      }
    }

    // let's update OfferFit templates
    for (let i = 0; i < updateTemplatesData.length; i++) {
      const templateToUpdate = updateTemplatesData[i]

      try {
        const response = await OFEService.updatePlatformTemplate(templateToUpdate.createdTemplateId, _experimenterName.value, clientName.value, removeIdFromObject(templateToUpdate))
        const offerfitCreatedTemplates = experimenterFormState.value?.offerfitCreatedTemplates
        offerfitCreatedTemplates[templateToUpdate.importedTemplateId] = response.result
        await updateExperimenterFormState({ offerfitCreatedTemplates })
      }
      catch (error: any) {
        handlePortalError(error, { defaultUserErrorText: `Couldn't update template "${templateToUpdate.name}" in the marketing platform. Please contact support.`, detailedErrorText: error.message })
        throw new Error('Error while trying to update templates')
      }
    }
  }

  const mappedGeneratedVariantsCombinatoryList = computed(() => {
    if (!experimenterFormState.value?.variantsDataMap) { return }
    return Object.fromEntries(Object.entries(experimenterFormState.value?.variantsDataMap).map(([campaignTemplateId, variantsData]) => {
      const variants = variantsData.generatedVariants?.map((variant: VariantsCartesianProduct, idx) => {
        return {
          id: idx,
          variantId: variant.id,
          subjectLineText: variant.subjectLine?.label,
          subjectLineTags: variantsData.generatedSubjectLinesVariants?.find(item => item.label === variant.subjectLine?.label)?.tags ?? [],
          ctaTags: variantsData.generatedCtaVariants?.find(item => item.label === variant.cta?.label)?.tags ?? [],
          ctaText: variant.cta?.label,
          heroImageData: {
            imageUrl: variant.image,
          },
        } satisfies SingleVariantRow
      })

      return [campaignTemplateId, variants]
    }))
  })

  async function updateEmailActionBank() {
    try {
      await UsecaseService.updateSelectedEmails(clientName.value, pipelineName.value, _experimenterName.value)
    }
    catch (e: any) {
      handlePortalError(e, { defaultUserErrorText: 'Couldn\'t update emails. Please try again later.', detailedErrorText: e.message })
      throw new Error('Error while trying to update selected emails')
    }

    await reloadUseCaseData()
  }

  async function updateVariantsChangedFlag() {
    await updateExperimenterFormState({ platformNeedsUpdateFlag: true })
    if (experimenterFormState.value?.sfmcJourneySubmitClicked) {
      await updateExperimenterFormState({ sfmcJourneyNeedsUpdateFlag: true })
    }
  }

  async function reuseImportedTemplatesForOfferfit() {
    if (!experimenterFormState.value?.offerfitCreatedTemplates) {
      await updateExperimenterFormState({ offerfitCreatedTemplates: {} })
    }
    const offerfitCreatedTemplates = {} as CreatedTemplatesMap
    experimenterFormState.value?.currentImportedTemplates.forEach((template: ImportedEmailTemplate) => {
      offerfitCreatedTemplates[template.id] = template
    })
    await updateExperimenterFormState({ offerfitCreatedTemplates })
  }

  const everyTemplatesHasMinOneVariant = computed(() => {
    return Object.values(experimenterFormState.value!.variantsDataMap).map(item => (item.generatedVariants || []).length).every(item => item > 0)
  })

  async function loadImages() {
    if (!experimenterFormState.value) { throw new Error('loadImages: experimenter is not defined') }
    const imagesResponse = await OFEService.getPlatformGalleryImages(_experimenterName.value, clientName.value)
    await updateExperimenterFormState({ importedImages: imagesResponse.result.images })
  }

  const mappedEmailTemplatesForExperimenter = computed(() => {
    return experimenterFormState.value?.currentImportedTemplates?.map((template) => {
      if (!template) {
        return {}
      }

      const variantData = experimenterFormState.value?.variantsDataMap[template.id]
      const elementsToVaryList = () => {
        if (!variantData) {
          return []
        }
        const labels = []
        if (variantData.varySubjectLine) {
          labels.push('Subject Lines')
        }
        if (variantData.varyCTA || variantData.selectedCTAHtmlElementIdentifiers?.cta || variantData.generatedCtaVariants?.length) {
          labels.push('CTA')
        }
        if (variantData.varyHeroImage || variantData.selectedCTAHtmlElementIdentifiers?.image || variantData.selectedImagesForVariants?.length) {
          labels.push('Hero Image')
        }
        return labels
      }

      const calculatedTotalVariants = variantData ? (variantData.generatedCtaVariants?.length || 1) * (variantData.generatedSubjectLinesVariants?.length || 1) * (variantData.selectedImagesForVariants?.length || 1) : 0

      return {
        id: template.id,
        title: template.name,
        previewThumbnailUrl: template.thumbnail_url,
        description: template.description,
        numberOfVariants: calculatedTotalVariants,
        elementsToVaryList: elementsToVaryList(),
        templateVariants: mappedGeneratedVariantsCombinatoryList.value![template.id],
      }
    })
  })

  async function updateTemplateDescription(templateId: string, description: string) {
    const currentImportedTemplates = toRaw(experimenterFormState.value?.currentImportedTemplates)
    const templateToUpdate = currentImportedTemplates?.find(template => template.id === templateId)
    if (templateToUpdate) {
      templateToUpdate.description = description
    }

    await updateExperimenterFormState({ currentImportedTemplates })
  }

  const mappedGeneratedVariantsCombinatoryListTotal = computed(() => {
    if (!experimenterFormState.value?.variantsDataMap) { return }
    return Object.values(experimenterFormState.value?.variantsDataMap).map(item => (item.generatedVariants || [])).flat().length
  })

  // Frequency
  async function updateDaysFrequencyActionBank({ selectedDaysArray, selectedFrequenciesArray }: { selectedDaysArray: string[], selectedFrequenciesArray: Frequency[] }) {
    const selectedDays = selectedDaysArray
    const selectedFrequencies = selectedFrequenciesArray
    if (!experimenterFormState.value?.name) { throw new Error('updateDaysFrequencyActionBank: experimenter is not defined') }
    try {
      await FormsStateService.updateSelectedFrequenciesAndDays(clientName.value, pipelineName.value, _experimenterName.value, selectedFrequencies, selectedDays)
      await vueQueryClient.invalidateQueries({ queryKey: ['formState', experimenterFormState.value?.name] })
      // this.daysOfWeekNeedUpdateFlag = !!selectedFrequenciesArray
    }
    catch (e: any) {
      handlePortalError(e, { defaultUserErrorText: 'Error while trying to update selected frequencies and days', detailedErrorText: e?.message })
    }
  }

  async function updateSelectedTimeZone(selectedTimeZone: string) {
    if (!experimenterFormState.value?.name) { throw new Error('updateSelectedTimeZone: experimenter is not defined') }
    try {
      await FormsStateService.updateSelectedTimeZone(
        clientName.value,
        pipelineName.value,
        _experimenterName.value,
        selectedTimeZone,
      )
      await vueQueryClient.invalidateQueries({ queryKey: ['formState', _experimenterName] })
    }
    catch (e: any) {
      handlePortalError(e, { defaultUserErrorText: 'Error while trying to update selected time of day', detailedErrorText: e?.message })
    }
  }

  async function updateGuardrails(guardrails: {
    baseEmailGuardrail?: EmailGuardrail
    variantsGuardrail?: EmailGuardrail
    subjectLineGuardrail?: EmailGuardrail
  }) {
    if (!experimenterFormState.value?.name) { throw new Error('updateGuardrails: experimenter is not defined') }
    try {
      await FormsStateService.updateGuardrails(
        clientName.value,
        pipelineName.value,
        _experimenterName.value,
        guardrails,
      )
      await vueQueryClient.invalidateQueries({ queryKey: ['formState', _experimenterName] })
    }
    catch (e: any) {
      handlePortalError(e, { defaultUserErrorText: 'Error while trying to update guardrails', detailedErrorText: e?.message })
    }
  }

  async function updateTimeOfDayActionBank(selectedTimeOfDay: [number, number]) {
    if (!experimenterFormState.value?.name) { throw new Error('updateTimeOfDayActionBank: experimenter is not defined') }
    try {
      await FormsStateService.updateSelectedTimeOfDay(
        clientName.value,
        pipelineName.value,
        _experimenterName.value,
        selectedTimeOfDay,
      )
      await vueQueryClient.invalidateQueries({ queryKey: ['formState', _experimenterName] })
    }
    catch (e: any) {
      handlePortalError(e, { defaultUserErrorText: 'Error while trying to update selected time of day', detailedErrorText: e?.message })
    }
  }

  // finish
  async function experimenterFinishSetUpHook(flow_id?: string) {
    const config = usecase.value?.config

    if (!config) { throw new Error('experimenterFinishSetUpHook: config is not defined') }
    if (!_experimenterName.value) { throw new Error('experimenterFinishSetUpHook: experimenter is not defined') }

    const platform = config.marketing_platform_connection?.platform

    if (!platform) { throw new Error('experimenterFinishSetUpHook: platform is not defined') }

    if (platform === INTEGRATION_PLATFORM.KLAVIYO) {
      try {
        await OFEService.experimenterFinishSetUpHook(_experimenterName.value, clientName.value, pipelineName.value, flow_id!)
        await reloadUseCaseData()
      }
      catch (error: any) {
        handlePortalError(error, { defaultUserErrorText: 'Error while trying to finish experimenter setup', detailedErrorText: error?.message })
      }
    }
  }

  const engagementExtensionNameToOverrwrite = computed(() => {
    const metadata = store.getters['pipeline/pipeline']?.metadata
    const engagementAudienceExtensions = metadata?.ingestion?.sfmc?.engagement_audiences_extension
    if (!engagementAudienceExtensions) {
      return 'Not found'
    }
    return Object.keys(engagementAudienceExtensions).find(key => engagementAudienceExtensions[key].includes(experimenterFormState.value?.name)) || 'Not found'
  })

  const baseAudienceExtensionNameToOverrwrite = computed(() => {
    const metadata = store.getters['pipeline/pipeline']?.metadata
    const baseAudienceExtensions = metadata?.ingestion?.sfmc?.base_audiences_extension
    if (!baseAudienceExtensions) {
      return 'Not found'
    }
    return Object.keys(baseAudienceExtensions).find(key => baseAudienceExtensions[key].includes(experimenterFormState.value?.name)) || 'Not found'
  })

  // useExperimenterState
  const getValidationState = async () => {
    const offerfitTemplates = experimenterFormState.value?.offerfitCreatedTemplates || {}
    let validationReponse: SFMCIntegrationValidationResponse | KlaviyoIntegrationValidationResponse | undefined
    let result = false
    try {
      validationReponse = await PlatformIntegrationService.validateIntegration(_experimenterName.value, clientName.value, pipelineName.value, Object.values(offerfitTemplates))

      if (validationReponse?.result && 'journey' in validationReponse.result && experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC) {
        let hasJourney = false
        if (validationReponse.result?.journey.email_template_nodes && validationReponse.result?.journey.status && validationReponse.result?.journey.api_event_trigger && validationReponse.result?.journey.multi_criteria_decision) {
          hasJourney = true
        }

        let hasData = false
        if (validationReponse.result?.recommendations_data_extension && validationReponse.result?.base_data_extension && validationReponse.result?.engagement_data_extension) {
          hasData = true
        }

        if (hasJourney && hasData) {
          result = true
        }
      }
      else if (validationReponse?.result && 'flow_id' in validationReponse.result && experimenterFormState.value?.platform === INTEGRATION_PLATFORM.KLAVIYO) {
        const {
          num_expected_trigger_splits,
          num_actual_trigger_splits,
          num_expected_email_nodes,
          num_actual_email_nodes,
          ...rest
        } = validationReponse.result

        const hasCorrectEmailNodes = num_expected_email_nodes === num_actual_email_nodes
        const hasCorrectTriggerSplits = num_expected_trigger_splits === num_actual_trigger_splits

        result = hasCorrectEmailNodes && hasCorrectTriggerSplits && Object.values(rest).every(val => !!val)
      }
      else if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.BRAZE) {
        result = true
      }

      return result
    }
    catch (e) {
      console.warn(e)
    }
    return false
  }

  const checkIntegrationSecretInVaultState = async () => {
    if (!_experimenterName.value) { throw new Error('isIntegrationDataInVault: experimenter is not defined') }

    try {
      const response = await PlatformIntegrationService.isIntegrationDataInVault(_experimenterName.value, clientName.value)
      return response.success
    }
    catch {
      return false
    }
  }

  const templatesLoaded = computed(() => {
    if (!experimenterFormState.value?.offerfitCreatedTemplates) { return false }
    return Object.keys(experimenterFormState.value?.offerfitCreatedTemplates).length !== 0
  })

  const { data: isIntegrationComplete } = useQuery({
    queryKey: [PlatformIntegrationService.INTEGRATION_ESTABLISHED, _experimenterName, clientName],
    queryFn: async () => checkIntegrationSecretInVaultState(),
    retry: false,
    placeholderData: false,
  })

  const { data: isIntegrationValid } = useQuery({
    queryKey: [PlatformIntegrationService.IS_INTEGRATION_VALID, _experimenterName, clientName],
    queryFn: async () => getValidationState(),
    enabled: templatesLoaded,
    retry: false,
    placeholderData: false,
  })

  const isPlatformIntegrationFormStateDataEmpty = computed(() => {
    if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.KLAVIYO) {
      return !experimenterFormState.value?.klaviyoApiKey
    }

    if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC) {
      return !experimenterFormState.value?.sfmcClientSecret
    }

    if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.BRAZE) {
      return !experimenterFormState.value?.brazeApiKey
    }

    return true
  })

  const isTypeCompleted = computed(() => {
    return !!experimenterFormState.value?.type
  })

  const isFrequencyAndDaysCompleted = computed(() => {
    if (!experimenterFormState.value) { return }

    // progress looks weird when data is provided but step is not completed
    // return !!actionBankState.value?.find((item: ConfigDimensionActionBank) => item.name === DimensionKey.FREQUENCY)?.actions?.length
    return !!experimenterFormState.value?.selectedFrequenciesArray?.length && !!experimenterFormState.value?.selectedDaysArray?.length
  })

  const isTimeCompleted = computed(() => {
    if (!usecase.value?.config.actionBanks) { return false }
    return !!usecase.value?.config.actionBanks?.find((item: ConfigDimensionActionBank) => item.name === DimensionKey.TIME)?.actions?.length
  })

  const isGuardrailsCompleted = computed(() => {
    const guardrailsStatus = experimenterFormState.value?.guardrailsStatus
    if (guardrailsStatus === 'active' || guardrailsStatus === 'inactive') { return true }
    return false
  })

  const isEmailCompleted = computed(() => {
    if (!usecase.value?.config.actionBanks) { return false }
    return !!usecase.value?.config.actionBanks?.find((item: ConfigDimensionActionBank) => item.name === DimensionKey.EMAIL)?.actions?.length
  })

  const isAudienceCompleted = computed(() => {
    if ((experimenterFormState.value?.platform) == null) {
      return false
    }
    return !!experimenterFormState.value?.selectedAudienceIds && !!experimenterFormState.value?.selectedOfferfitGroupPercent && !!experimenterFormState.value?.selectedControlGroupPercent
  })

  const actionBankProgressStateMap = computed(() => {
    if (!usecase.value?.config.actionBanks) { return }

    return {
      isEmailCompleted: isEmailCompleted.value,
      isTimeCompleted: isTimeCompleted.value,
      isFrequencyAndDaysCompleted: isFrequencyAndDaysCompleted.value,
    }
  })

  const baseEmailsCount = computed(() => {
    if (!experimenterFormState.value) { return }
    const count = (experimenterFormState.value?.currentImportedTemplates || []).length
    return `${count} base email${count > 1 ? 's' : ''}`
  })

  const variantsCount = computed(() => {
    if (!experimenterFormState.value) { return }
    let count = 0
    Object.keys(experimenterFormState.value?.variantsDataMap || {})?.forEach((key: string) => {
      const multiplied = (experimenterFormState.value?.variantsDataMap?.[key]?.generatedCtaVariants?.length || 1) * (experimenterFormState.value?.variantsDataMap?.[key]?.generatedSubjectLinesVariants?.length || 1) * (experimenterFormState.value?.variantsDataMap?.[key]?.selectedImagesForVariants?.length || 1)
      count += multiplied
    })
    return count
  })

  const subjectLinesCount = computed(() => {
    if (!experimenterFormState.value) { return }
    let count = 0
    Object.keys(experimenterFormState.value?.variantsDataMap || {})?.forEach((key: string) => {
      count += experimenterFormState.value?.variantsDataMap?.[key]?.generatedSubjectLinesVariants?.length || 0
    })
    return count
  })

  const firstUnfinishedFormRouterTarget: ComputedRef<FormRouterTarget | null> = computed(() => {
    if (!isTypeCompleted.value && isCalendarSendsEnabled.value) { return { moduleName: Level4Module.TYPE } as FormRouterTarget }
    if (!isIntegrationComplete.value) { return { moduleName: Level4Module.INTEGRATIONS } as FormRouterTarget }
    if (!isAudienceCompleted.value) { return { moduleName: Level4Module.AUDIENCE } as FormRouterTarget }
    if (!isEmailCompleted.value) {
      return experimenterFormState.value!.currentImportedTemplates.length > 0
        ? {
            moduleName: Level4Module.TEMPLATES,
            stepName: TemplatesSteps.EMAILS,
          } as FormRouterTarget
        : {
            moduleName: Level4Module.TEMPLATES_SELECTION,
            stepName: TemplatesSelectionSteps.CHOOSE,
          } as FormRouterTarget
    }
    if (!isFrequencyAndDaysCompleted.value) { return { moduleName: Level4Module.FREQUENCY } as FormRouterTarget }
    if (!isTimeCompleted.value) { return { moduleName: Level4Module.TIME } as FormRouterTarget }
    if (!isGuardrailsCompleted.value && isEmailVariantsGuardrailsEnabled.value) { return { moduleName: Level4Module.GUARDRAILS } as FormRouterTarget }
    if (!isIntegrationValid.value && experimenterFormState.value?.platform === INTEGRATION_PLATFORM.KLAVIYO) { return { moduleName: Level4Module.LAUNCH_KLAVIYO } as FormRouterTarget }
    if (!isIntegrationValid.value && experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC) { return { moduleName: Level4Module.LAUNCH_SFMC } as FormRouterTarget }

    return null
  })

  const allComputedStepsCompletion = computed(() => {
    const progressMap: Record<string, boolean | undefined> = {
      isIntegrationComplete: isIntegrationComplete.value,
      isAudienceCompleted: isAudienceCompleted.value,
      isEmailCompleted: isEmailCompleted.value,
      isFrequencyAndDaysCompleted: isFrequencyAndDaysCompleted.value,
      isTimeCompleted: isTimeCompleted.value,
    }

    if (isCalendarSendsEnabled.value) {
      progressMap.isTypeCompleted = isTypeCompleted.value
    }

    if (isEmailVariantsGuardrailsEnabled.value) {
      progressMap.isGuardrailsCompleted = isGuardrailsCompleted.value
    }

    if (experimenterFormState.value?.platform === INTEGRATION_PLATFORM.SFMC || experimenterFormState.value?.platform === INTEGRATION_PLATFORM.KLAVIYO) {
      progressMap.isIntegrationValid = isIntegrationValid.value
    }
    debug('progressMap', progressMap)
    return Object.values(progressMap)
  })

  const totalCompletedSteps = computed(() => {
    const result = allComputedStepsCompletion.value.filter(Boolean).length
    debug(_experimenterName.value, { totalCompletedSteps: result })
    return result
  })

  const totalSteps = computed(() => {
    const result = allComputedStepsCompletion.value.length
    debug(_experimenterName.value, { totalSteps: result })
    return result
  })

  const experimenterPercentProgress = computed(() => {
    if (!totalCompletedSteps.value) { return }
    return (totalCompletedSteps.value / totalSteps.value) * 100
  })

  const getRecentTimeZones = async () => {
    const recentTimeZones = await FormsStateService.getRecentTimeZones(clientName.value, pipelineName.value)
    return recentTimeZones
  }

  const getRecentTimeOfDays = async () => {
    const recentTimeOfDays = await FormsStateService.getRecentTimeOfDays(clientName.value, pipelineName.value)
    return recentTimeOfDays
  }

  return {
    isPlatformIntegrationFormStateDataEmpty,
    experimenterFormState,
    isFormStateLoading,
    isLive,
    updateExperimenterFormState,
    setBrazeIntegrationCredentials,
    setSFMCIntegrationCredentials,
    setKlaviyoIntegrationCredentials,
    deleteFormState,
    setMarketingPlatform,
    createIntegrationDataInVault,
    setMarketingCampaignId,
    setBrazeEmailFrom,
    setMarketingMessageVariationId,
    setBrazeDashboardUrl,
    setBrazeApiKey,
    setBrazeAppId,
    updateOFEConfigs,
    updateSelectedTypeAndDuration,
    validateIntegration,
    validateAudience,
    loadAudiences,
    updateSelectedAudienceId,
    updateGuardrails,
    updateBAUReportingConfiguration,
    reuseImportedTemplatesForOfferfit,
    createOrUpdatePlatformTemplates,
    updateTemplateDescription,
    updateEmailActionBank,
    updateSelectedAudienceRegimePercent,
    loadImages,
    setImportedCampaigns,
    addCurrentImportedTemplatesFromImportedCampaign,
    loadCampaigns,
    loadCampaignTemplates,
    checkWhetherImportedTemplatesAreAlreadyInUse,
    updateDaysFrequencyActionBank,
    updateSelectedTimeZone,
    updateTimeOfDayActionBank,
    experimenterFinishSetUpHook,
    getRecentTimeZones,
    getRecentTimeOfDays,
    engagementExtensionNameToOverrwrite,
    baseAudienceExtensionNameToOverrwrite,
    campaignTemplatesLoadingMap,
    mappedGeneratedVariantsCombinatoryList,
    everyTemplatesHasMinOneVariant,
    mappedGeneratedVariantsCombinatoryListTotal,
    generatedVariantsCombinatoryList,
    mappedEmailTemplatesForExperimenter,
    allConfirmedVariantListLength,
    presentCategoriesOnGeneratedVariants,
    selectedAudienceSize,
    isIntegrationComplete,
    isIntegrationValid,
    actionBankProgressStateMap,
    isTypeCompleted,
    isAudienceCompleted,
    isFrequencyAndDaysCompleted,
    isTimeCompleted,
    isGuardrailsCompleted,
    isEmailCompleted,
    experimenterPercentProgress,
    baseEmailsCount,
    variantsCount,
    subjectLinesCount,
    firstUnfinishedFormRouterTarget,
    totalCompletedSteps,
    totalSteps,
  }
}
